import SmoothScroll from "smooth-scroll";

let startPos = 0,
  winScrollTop = 0;

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

// SPナビゲーションの表示と非表示
function navControl() {
  const button = document.querySelector('.sp-menu-btn');
  const nav = document.querySelector('.menu-sp');
  const headerLogo = document.querySelector('.header__logo');

  button.addEventListener('click', control);
  nav.addEventListener('click', control);

  function control() {
    if(!nav.classList.value.match(/menu-sp--on/)) {
      nav.classList.add('menu-sp--on');
      button.classList.add('sp-menu-btn--on');
      headerLogo.style.opacity = 0;
    } else {
      nav.classList.remove('menu-sp--on');
      button.classList.remove('sp-menu-btn--on');
      headerLogo.style.opacity = 1.0;
    }
  }
}
navControl();